import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import '../node_modules/react-modal-video/scss/modal-video.scss';

import reportWebVitals from './reportWebVitals';
import FooterBottom from './components/Footer';
import MenuNavigation from './components/Menu';
import {APP_AUTH_ROUTE, OTP_CODE_ROUTE, STRIPE_PK} from './utils/constants';
// const Login   = React.lazy(()=>import( './login/Login'));
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
const Plans  = React.lazy(()=>import( './payments/Plans'));
const AppLoginFinal = React.lazy(()=>import('./login/AppLoginFinal'));
const OTPCode = React.lazy(()=>import( './login/OTPCode'));
const OTPEmail = React.lazy(()=>import('./login/OTPEmail'));
const ZoomInstructionsPost  = React.lazy(()=>import( './blog/posts/ZoomInstructions'));
const App = React.lazy(()=>import('./App'));
const Update = React.lazy(()=> import('./Update'));
const Uninstall = React.lazy(()=> import('./Uninstall'));
const HowToUse  = React.lazy(()=> import('./HowToUse'));
const Upgrade  = React.lazy(()=> import('./payments/Upgrade'));
const BlogCover  = React.lazy(()=> import('./blog/BlogCover'));
const PrivacyPolicy = React.lazy(()=> import('./legal/PrivacyPolicy'));
const TermsOfUse  = React.lazy(()=> import('./legal/TermsOfUse'));
const SignUp  = React.lazy(()=> import('./login/SignUp'));
const Account  = React.lazy(()=> import('./login/Account'));
const ForgotPassword  = React.lazy(()=> import('./login/ForgotPassword'));
const Card  = React.lazy(()=> import( './payments/Card'));



ReactDOM.render(
    <React.Suspense fallback={<span>Loading...</span>}>
        <Router>
          <MenuNavigation />
          <Switch>
            <Route path="/zoom-background-noise-reduction">
              <ZoomInstructionsPost />
            </Route>
            <Route path="/privacy">
              <PrivacyPolicy />
            </Route>
            <Route path="/terms">
              <TermsOfUse />
            </Route>
            <Route path="/update">
              <Update />
            </Route>
            <Route path="/uninstall">
              <Uninstall />
            </Route>
            <Route path="/how-to-use">
              <HowToUse />
            </Route>
            <Route path="/plans">
              <Plans />
            </Route>
            <Route path="/blog">
              <BlogCover />
            </Route>
            <Route path="/login">
              <OTPEmail />
            </Route>
            <Route path="/signup">
              <SignUp />
            </Route>
            <Route path="/account">
              <Account />
            </Route>
            <Route path="/upgrade">
              <Upgrade />
            </Route>
            <Route path="/otp-email">
              <OTPEmail />
            </Route>
            <Route path={OTP_CODE_ROUTE}>
              <OTPCode />
            </Route>
            <Route path={APP_AUTH_ROUTE} >
              <AppLoginFinal />
            </Route>
            <Route path="/get-elevate" component={Card} />
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/">
              <App />
            </Route>
          </Switch>
          <FooterBottom/>
        </Router>
    </React.Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
