import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCEyaNb-NO-Nh3eE4DhpR06aA_tRo-Fvns",
  authDomain: "noise-cancellation-c6077.firebaseapp.com",
  projectId: "noise-cancellation-c6077",
  storageBucket: "noise-cancellation-c6077.appspot.com",
  messagingSenderId: "173826447696",
  appId: "1:173826447696:web:937c68de6567ebb6b83455",
  measurementId: "G-9X7R5Y9JJ7"
}

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAnalytics = firebase.analytics();
const firebaseFirestore = firebase.firestore();
const firebaseAuth = firebase.auth();

firebase.analytics();


firebaseAuth.onAuthStateChanged((user) => {
    // console.log("Auth state changed!");
    if (user) {
      // console.log('setting user id');
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      firebaseAnalytics.setUserId(user.uid);
    } else {
      // console.log("user signed out");
    }
  });

export {
    firebaseAuth,
    firebaseFirestore,
    firebaseAnalytics,
    firebase
}