export const STRIPE_PK = "pk_live_51Hv95nGUkBM6Wo2v6FYITOG66hJEOeB1FPBPqEPf2v818nSLCcWY7TzHKakmXg6kFK1n7sGoWs9A5WkW8wTJydgA00GFncC7yJ" //"pk_test_51Hv95nGUkBM6Wo2vNN3pzeXdi2EY4qySbsWnXMqBa6D848A5GzxyAcfWHlSKHpYIpnctv6eo5dSAQkefBBaKeAqg00rcFxTTru"
export const GET_PLANS_SUBSCRIPTIONS_AND_PAYMENT_METHODS_URL = "/api/get_plans_and_subscription_and_payment_methods"
export const GET_PLANS_AND_SUBSCRIPTION_URL = "/api/get_plans_and_subscription"
export const CANCEL_SUBSCRIPTION_B_URL = "/api/cancel_subscription"
export const SIGNUP_FOR_BASIC_PLAN = '/api/signup_for_basic_plan'
export const GET_PAYMENT_METHODS = '/api/get_payment_methods'
export const REMOVE_PAYMENT_METHOD_URL = '/api/remove_payment_method'
export const ADD_PAYMENT_METHOD_URL = '/api/add_payment_method'
export const VALIDATE_SUBSCRIPTION_PREREQUISITES_URL = '/api/validate_subscription_prerequisites'
export const GET_PLANS_AND_SUBSCRIPTION_AND_PAYMENT_METHODS_AND_CREDITS_URL = '/api/get_plans_and_subscription_and_payment_methods_and_credits'
export const GENERATE_OTP_TOKEN = '/api/generateTokenForEmail'
export const VERIFY_OTP_TOKEN = '/api/verifyTokenForEmail'
export const ADD_CREDITS_FB_URL = '/api/add_credits_fb'
export const APP_AUTHORIZATION_URL = '/api/app_authorization'
export const BACKEND_URL = "https://www.utterly.app" //"http://192.168.1.3:80"
export const ANNUAL_ELEVATE_PRICE_ID = "price_1Iz5DTGUkBM6Wo2vCgmUtWVa" //"price_1JLEcCGUkBM6Wo2vmGXHBZSA" -test
export const MONTHLY_ELEVATE_PRICE_ID = "price_1IaoDjGUkBM6Wo2vQIm2alqQ" //"price_1IVnrTGUkBM6Wo2vh9xg2wxl"-test

// prerequisites (NEED TO MATCH CONSTANTS IN THE SERVER constants)
export const USER_NOT_REGISTERED = "User needs to register for the full account and verify email";
export const NO_PAYMENT_METHODS = "No payment methods"

// plan names
export const BASIC_PLAN_NAME = 'Basic';

// routes
export const PLANS_ROUTE = '/plans';
export const FINISH_LOGIN_URL = process.env.REACT_APP_FINISH_LOGIN_URL
export const FINISH_LOGIN_ROUTE = '/account';
export const PROFILE_ROUTE = '/account';
export const CARD_ROUTE = '/get-elevate';
export const LOGIN_ROUTE = "/login";
export const SUBSCRIPTION_CONFIRMATION_ROUTE = "/confirmation";
export const SIGNUPOPTIONS_ROUTE = "/signup";
export const OTP_CODE_ROUTE = '/otp-code';
export const APP_AUTH_ROUTE = "/app-auth"

export const PLANS_SUBS_URL_PARAM_NAME = 'subscribe'

export const SUPPORT_EMAIL = "support@utterly.app"

// constants for legal
export const COMPANY_NAME  = "Utterly Technologies, Inc."
export const SHORT_COMPANY_NAME = "Utterly Technologies, Inc."
export const APP_NAME = "Utterly"
export const UPDATE_DATE = "November 19, 2020"


export const LOCAL_STORAGE_KEY_REDIRECT_AFTER_SIGN_IN = 'redirectAfterSignIn';
export const LOCAL_STORAGE_KEY_REDIRECT_AFTER_ADDING_CARD = "redirectAfterAddingCard";
export const LOCAL_STORAGE_KEY_REDIRECT_AFTER_SIGN_IN_PARAM = 'redirectAfterSignInParams';
export const LOCAL_STORAGE_KEY_REDIRECT_AFTER_ADDING_CARD_PARAM = "redirectAfterAddingCardParams";
export const LOCAL_STORAGE_KEY_EMAIL_FOR_SIGNIN = 'emailForSignIn';
export const LOCAL_STORAGE_KEY_EMaIL_FOR_OTP_SIGNIN = 'emailForOTPSignIn'
export const LOCAL_STORAGE_KEY_APP_CODE = 'keyAppCode' 

export const RECAPTCHA_SITE_KEY = "6Lfd014bAAAAAJkPOw1CMW2aZ13MlRqrdYndv-P6"

export const NUMBER_OF_SUBSCRIPTION_REMINDERS = 1

export const TRIAL_PERIOD_DAYS = 3 // <- must match the server consts!
