import React, {useState, Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './css/Menu.css'
import 'semantic-ui-css/semantic.min.css'
import { Spinner } from 'react-bootstrap';
import { firebaseAnalytics, firebaseAuth, firebaseFirestore, firebase } from '../environment/config';
import { Image, Container, Menu, Input, Header, Popup, Button, Dropdown, Message, Icon } from 'semantic-ui-react'


class MenuNavigation extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isVideoOpen: false,
            email: null,
            activeItem: "plugin",
            isSignedIn: false, 
            isEmailSent: false,
            downloadSuccess: null,
            downloadError: null,
            signedUserEmail: null,
            hasLoaded: false,
            elevate: "Download Elevate"
        } 
    }
     
      async componentDidMount() {
        this.unregisterAuthObserver = firebaseAuth.onAuthStateChanged(async (user) => {
            if (!user) {
                // console.log("> user is NOT signed in - signing in anonymously")
                await firebaseAuth.signInAnonymously()
                .then(() => {
                    console.log('User signed in anonymously');
                })
                .catch(error => {
                    if (error.code === 'auth/operation-not-allowed') {
                        console.log('Enable anonymous in your firebase console.');
                    }
                });
            } else {
                console.log("> user is signed in - just listening for changes")
                if (!user.isAnonymous && user.email !== null) 
                {
                    // console.log(user)
                    await firebaseAuth.fetchSignInMethodsForEmail(user.email)
                    .then((signInMethods) => {
                        if (signInMethods.indexOf(
                            firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) != -1 || user.emailVerified) 
                        {
                            this.setState({signedUserEmail: user.email})
                        }
                    })
                    .catch((error) => {})
                }
            }
            this.setState({hasLoaded: true})
        })
      }

      signOut = () =>
        {
            firebaseAuth.signOut().then(() => {
                firebaseAnalytics.logEvent('sign-out-from-menu')
                window.location.reload(); 
            // Sign-out successful.
            }).catch((error) => {
                alert(error)
            })
        }

      render() {
          return (
              <>
                <div className="non-mobile-menu">
                    <Menu id="menu-custom" secondary>
                        <Menu.Item
                        href="https://www.utterly.app"
                        >
                        <Image src="utterly_logo_nobg.png" style={{height:40, marginTop:15, marginBottom:15, cursor: "pointer"}} />
                        </Menu.Item>
                        <Menu.Menu id="fonts" style={{marginLeft: "auto", marginRight: "auto", fontWeight: 600}}>
                        <Menu.Item
                            href="/"
                            id="fonts"
                            style={{fontWeight: "bold"}}
                            name='Home'
                            onClick={()=>{firebaseAnalytics.logEvent('home-clicked-from-menu')}}
                        />
                        <Menu.Item
                            href="/plans"
                            id="fonts"
                            style={{fontWeight: "bold"}}
                            name='Pricing'
                            onClick={()=>{firebaseAnalytics.logEvent('plans-clicked-from-menu')}}
                        />
                        <Menu.Item
                            id="fonts"
                            href="/blog"
                            style={{fontWeight: "bold"}}
                            name='Blog'
                            onClick={()=>{firebaseAnalytics.logEvent('blog-from-menu')}}
                        />
                        <Menu.Item
                            id="fonts"
                            style={{fontWeight: "bold"}}
                            href="https://discord.gg/KEebD5XJRg"
                            target="_blank"
                            onClick={()=>{firebaseAnalytics.logEvent('community-menu')}}
                            name='Community & Support'
                            content='Community & Support'
                        />
                        {/* <Dropdown style={{fontWeight: "bold"}} id="fonts" item text='Other Products'>
                            <Dropdown.Menu id="fonts">
                            <Dropdown.Item onClick={()=>{firebaseAnalytics.logEvent('getutterly-click-from-menu')}} style={{fontWeight: "bold"}} href="https://getutterly.com" target="_blank" id="fonts">Visit Utterly pronunciation app</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                        </Menu.Menu>
                        <Menu.Menu position="right">
                            {
                                (this.state.hasLoaded === true) 
                                ?
                                ((this.state.signedUserEmail === null)
                                ?
                                <Menu.Item
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    name='Login'  
                                >
                                    <button className="circle-login-button" onClick={()=>{firebaseAnalytics.logEvent('contact-us-menu'); window.location='/login'}}>Login</button>
                                </Menu.Item>
                                :
                                <Dropdown 
                                item 
                                text='Account'
                                id="fonts"
                                style={{fontWeight: "bold"}}
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item style={{textAlign: "left"}} disabled><span style={{fontWeight: 600}}><Icon name='user circle' />{this.state.signedUserEmail}</span></Dropdown.Item>
                                        {/* <Dropdown.Item>Refer a Friend</Dropdown.Item> */}
                                        <Dropdown.Item style={{textAlign: "left"}} onClick={()=>{firebaseAnalytics.logEvent('menu: click utterly elevate upgrade')}} href="/upgrade"><Icon name='star' />Upgrade to Elevate (Pro)</Dropdown.Item>
                                        <Dropdown.Item style={{textAlign: "left"}} onClick={()=>{firebaseAnalytics.logEvent('menu: click settings')}} href="/account">Settings</Dropdown.Item>
                                        <Dropdown.Item onClick={this.signOut} style={{textAlign: "left"}}>Sign out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>)
                                :
                                <Spinner animation="border" role="status" style={{alignSelf: "center"}}>
                                    {/* <span className="sr-only">Loading...</span> */}
                                </Spinner>  
                                // <Menu.Item
                                //     href="/account"
                                //     id="fonts"
                                //     style={{fontWeight: "bold"}}
                                //     name='Account'  
                                //     onClick={()=>{firebaseAnalytics.logEvent('account-from-menu')}} 
                                // >
                                //     {/* User: <a href="/account">  {this.state.signedUserEmail}</a> */}
                                // </Menu.Item>
                            }
                        </Menu.Menu>
                    </Menu>
                    </div>
                <div className="mobile-menu">
                    <Menu secondary>
                        <Menu.Item
                            href="https://www.utterly.app"
                        >
                            <Image src="utterly_logo_nobg.png" style={{height:30, marginTop:15, marginBottom:15, marginLeft:0, cursor: "pointer"}} />
                        </Menu.Item>
                        <Menu.Menu position='right'>
                            <Dropdown
                                item
                                icon ='bars'
                                >
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    href="/"
                                    onClick={()=>{firebaseAnalytics.logEvent('home-menu-mobile-click')}}
                                    text='Home'
                                    />
                                    <Dropdown.Item
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    href="/plans"
                                    onClick={()=>{firebaseAnalytics.logEvent('plans-clicked-from-mobile-menu')}}
                                    text='Pricing'
                                    />
                                    <Dropdown.Item 
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    href="/account"
                                    text='Account'
                                    onClick={()=>{firebaseAnalytics.logEvent('account-from-menu-mobile')}}
                                    />
                                    <Dropdown.Item
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    href="https://discord.gg/KEebD5XJRg"
                                    target="_blank"
                                    onClick={()=>{firebaseAnalytics.logEvent('community-menu-mobile')}}
                                    text='Community & Support'
                                    />
                                    <Dropdown.Item
                                        id="fonts"
                                        style={{fontWeight: "bold"}}
                                        href="/upgrade"
                                        onClick={()=>{firebaseAnalytics.logEvent('mobile-menu: click utterly elevate upgrade')}}
                                        text='Utterly Elevate'
                                    />
                                    {
                                    (this.state.signedUserEmail === null)
                                    ?
                                    <></>
                                    :
                                    <Dropdown.Item
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    onClick={this.signOut}
                                    text='Sign Out'
                                    />
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Menu>
                    </Menu>
                </div>
              </>
          )
      }
}

export default MenuNavigation